import {
  FETCH_APPS_LOADING,
  FETCH_APPS_SUCCESS,
  FETCH_APPS_ERROR
} from "../actions/types";

const initialState = {
  loading: false,
  error: null,
  success: false,
  data: []
};

export const apps = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_APPS_LOADING:
      return {
        ...state,
        loading: true,
        success: false
      };
    case FETCH_APPS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload
      };
    case FETCH_APPS_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      };

    default:
      return state;
  }
};
