/* eslint-disable no-useless-escape */
export const  getParameterByName= (name: string, url = window.location.href)=> {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2]);
}

/**
 * add language fallback for translations
 * suppose if a key is not exist in any file, will search in fallback lanhuage file
 * @param {*} code
 * @param {*} fallback
 * @returns
 */
export const addFallbackForTranslations = (code:string, t:any, params={}, fallback='en') => {
  let translation = t(code, params);
  if (translation === code) {
    translation = t(code, {
      ...params,
      lng: fallback
    });
  }
  return translation;
};

/**
 * translation wrapper
 * @param {*} translation
 * @returns
 */
export const translationWrapper = (
  translation: any,
  addKeyFromSession = true
) => {
  const { t } = translation;
  const getTranslation = (key: string, params = {}) => {
    if (key) {
      if (key.startsWith("global.")) {
        return addFallbackForTranslations(key, t, params);
      } else {
        if (addKeyFromSession) {
          const getThePageTitle =
            sessionStorage.getItem("pageIdentifierKey") || "";
          return addFallbackForTranslations(
            `${getThePageTitle}.${key}`,
            t,
            params
          );
        } else {
          return addFallbackForTranslations(key, t, params);
        }
      }
    }
    return "";
  };
  return { t: getTranslation };
};
