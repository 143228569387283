import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import MainApp from './App';
import { store } from './app-redux/store';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { AppLoader } from 'core/AppLoader';
import * as Sentry from "@sentry/react";
import { SENTRY_CONFIG, SENTRY_KEY } from 'sentry.config';
import { isLocal } from 'app.constant';
import './i18n.js';

if (!isLocal && SENTRY_KEY) {
  Sentry.init(SENTRY_CONFIG);
}

(async function loadAppLoader() {
  // tslint:disable-next-line
  const myapp = new AppLoader();
  const isRedirecting = await myapp.load();

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHY_DARKLY_ID ?? ''
  });

  if (!isRedirecting) {
    ReactDOM.render(
      (
        <Sentry.ErrorBoundary showDialog>
          <LDProvider>
            <Provider store={store}>
              <MainApp />
            </Provider>
          </LDProvider>
        </Sentry.ErrorBoundary>
      ),
      document.getElementById('root'),
    );
  }
})();

ReactDOM.render(<div />, document.getElementById('root'));
