import * as Sentry from '@sentry/react';
import { getHeaders } from "core/common-methods";
import { Dispatch } from "redux";
import { request } from "../../core/axios";
import {
  FETCH_USER_PROFILE_ERROR,
  FETCH_USER_PROFILE_LOADING,
  FETCH_USER_PROFILE_SUCCESS
} from "./types";

const SESSION_STORAGE_ACCESS_TOKEN = "tempTokens";

export function fetchUserProfileLoading () {
  return {
    type: FETCH_USER_PROFILE_LOADING
  };
}

export function fetchUserProfileError (error: any) {
  return {
    type: FETCH_USER_PROFILE_ERROR,
    payload: error
  };
}

export function fetchUserProfileSuccess (user: any) {
  Sentry.setUser({
    user: {
      id: user?.id,
      email: user?.email,
      name: user?.name,
      language: user?.locale
    }
  });  
  return {
    type: FETCH_USER_PROFILE_SUCCESS,
    payload: user
  };
}
export const fetchUserProfile = () => async (dispatch: Dispatch) => {
  dispatch(fetchUserProfileLoading());
  try {
    const res = await request({
      method: "get",
      url: `/v2/accounts/me`,
      headers: getHeaders(SESSION_STORAGE_ACCESS_TOKEN)
    });
    if (res?.data) {
      dispatch(fetchUserProfileSuccess(res.data));
    }
    return res.data;
  } catch (error) {
    dispatch(fetchUserProfileError(error));
    throw error;
  }
};
