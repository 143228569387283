/**
 * App Loader : Before Loading App in the dom, we need to load few meta data in our system.
 * App Loader will be used to control the sequence in which things are loaded.
 * 1. We need to determine if user is logged in or not.
 */
import qs from "querystring";
import {
  redirectUserToAvailableProductsDeepAction,
  isAccessTokenAvailable
} from "core/common-methods";
import { request } from "./axios";
import { CWEnv, initCWElements } from '@cw-elements/config';
import { initAnalytics } from '@cw-elements/analytics-provider';

const locationSearch = window.location.search.replace("?", ""); // temp hack to replace ?
const query = qs.parse(locationSearch);
const campaignSign = (query.source_campaign || query.source_campaign_signin);
const distributionSign = (window.location.pathname === "/distribution-link" && query.source_distribution_data !== undefined);

class AppLoader {
  private readonly metaData: {
    isUserLoggedIn?: boolean;
  };

  constructor() {
    this.metaData = {};
  }
  public getMetaData() {
    return this.metaData;
  }
  public isUserLoggedIn() {
    const self = this;
    return new Promise((resolve) => {
      setTimeout(() => {
        self.metaData.isUserLoggedIn = true;
        resolve(true);
      }, 1000);
    });
  }
  public async load() {

    if (window.location.pathname.split("/")[1] === "action" && query.state) {
      const state = atob(query.state as string).split(":");
      redirectUserToAvailableProductsDeepAction(state[0], state[1]);
      return true;
    }

    if ((campaignSign || distributionSign) && (isAccessTokenAvailable())) { // campaign or distribution with the user signed in.
      const confirmationMethod = campaignSign ? "campaign" : "distribution";
      const methodData = campaignSign ? (query.source_campaign ? query.source_campaign as string : query.source_campaign_signin as string) : query.source_distribution_data as string;
      redirectUserToAvailableProductsDeepAction(confirmationMethod, methodData);
      return true;
    }

    this.initClients();

    if (process.env.REACT_APP_SEGMENT_KEY) {
      initAnalytics(process.env.REACT_APP_SEGMENT_KEY)
    }
    return false;
  }

  private initClients () {
    initCWElements({
      environment: process.env.REACT_APP_ENV_NAME as CWEnv,
      axiosInstance: request,
    })
  }
}

export { AppLoader };
