import React from 'react';
import { useTranslation } from "react-i18next";
import Cropwise from 'assets/images/cropwise.svg'
import SyngentaDigital from 'assets/images/syngenta_digital.svg';
import IllustrationLetsstart from 'assets/images/illustration_letsstart.svg';
import { getBrandName } from 'core/utils/BrandingUtils';
import { iff } from 'core/iff';
import { LoadingOutlined } from '@ant-design/icons';
import { translationWrapper } from 'utils/commonMethods';

export const Loader = (props: any) => {

    const { t } = translationWrapper(useTranslation(), false);

    return (
        <div className='loading-container'>
        <div>
            <img src={iff(getBrandName() === 'Syngenta Digital', SyngentaDigital, Cropwise)} alt={getBrandName()} />
        </div>
        <div className='illustration-position'>
            <img src={IllustrationLetsstart} alt='Illustration Letsstart' />
        </div>
        <div className='loading-text'>{props.msg ?? t('global.text.loading_text')}</div>
        <LoadingOutlined style={{ fontSize: 24, color: "#14803C" }}/>
        </div>
    );
};
