import { ADD_PASSWORD } from "../actions/types";
  
  const initialState = {
    password: ''
  };
  
  export const signUpPass = (state = initialState, action: any) => {
    if(action.type === ADD_PASSWORD) {
      return {
        ...state,
        password: action.payload
      }
    } else {
      return state
    }
  }