/*tslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable react-hooks/exhaustive-deps */
import { history, URL_CONSTANTS } from "core/history";
import { ConfigProvider } from "antd";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import React, { Suspense, useEffect } from "react";
import { Router, Switch } from "react-router-dom";
import qs from "querystring";
import "./main.less";
import { redirectPost } from "core/axios";
import { getBrandName } from "core/utils/BrandingUtils";
import {
  setQueryDetails,
  getQueryDetails,
  getOAuthUrl,
  resetQueryDetails,
  getSessionUserDetails,
  clearAuthPostFlag,
  getAuthPostFlag,
  isSessionStorageAccessTokenAvailable,
  getDefaultRedirectURI,
  getSessionSignupInit,
  setToSession
} from "core/common-methods";
import { Loader } from "components/loader/Loader";
import {
  fetchUserProfile
} from "app-redux/actions/user.actions";
import { connect } from "react-redux";
import { initRollout } from "app-redux/actions/rollout.actions";
import * as Sentry from "@sentry/react";
import { SentryRoute } from "sentry.config";

const AsyncMainRoutes = React.lazy(() => import("routes"));

const AppComponent = (props: any) => {
  document.title = getBrandName();
  const urlDetails = window.location.href;
  const locationSearch = window.location.search.replace("?", ""); // temp hack to replace ?
  const query = qs.parse(locationSearch);
  const campaignSign = (query.source_campaign || query.source_campaign_signin);
  const distributionSign = (window.location.pathname === "/distribution-link" && query.source_distribution_data !== undefined);
  
  if (getAuthPostFlag()) {
    setTimeout(() => {
      clearAuthPostFlag();
    }, 100);
    const authTokenRequestDTO = getSessionUserDetails();
    if (
      !isEmpty(get(authTokenRequestDTO, "username", "")) &&
      !isEmpty(get(authTokenRequestDTO, "password", ""))
    ) {
      redirectPost(`/login`, authTokenRequestDTO);
    }
  }

  if (getQueryDetails()) {
    const queryDetails = getQueryDetails();
    resetQueryDetails();
    window.location.href = `${queryDetails}&oAuthDone=true`;
  }
  if (query.new_user_hint) {
    history.push(URL_CONSTANTS.SIGNUP_LOCAL());
  }
  if (query.signup_hint) {
    const sessionSignupInit = getSessionSignupInit();
    history.push(URL_CONSTANTS.SIGNUP_AUTH() + (sessionSignupInit ? sessionSignupInit + "&" : "?") + "signup_hint=true");
  }

  if (query.client_id) {
    setToSession("client_id", query.client_id);
  } else {
    setToSession("client_id", process.env.REACT_APP_ACCOUNTS_CLIENT_ID);
  }


  const startOauthFlow = (!query.oAuthDone && ((query.redirect_uri && query.client_id) || (campaignSign || distributionSign))); // used for /signup path
  // Set Source Campaign (Sign-in flow) data
  if (window.location.pathname === "/signin" && query.source_campaign_signin !== undefined) {
    setToSession("source_campaign_signin_id", query.source_campaign_signin);
  } else {
    setToSession("source_campaign_signin_id", false);
  }

  // Set Distribution Link (Sign-in flow) data
  if (distributionSign) {
    setToSession("source_distribution_data", query.source_distribution_data);
    setToSession("persistent_source_distribution_data", query.source_distribution_data);
  } else {
    setToSession("source_distribution_data", false);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
  
    const fetchCurrentUser = async () => {
      if (isSessionStorageAccessTokenAvailable()) {
        const userResponse = await props.fetchUserProfile();
        if (userResponse) {
          const { id } = userResponse || {};
          props.initRollout({ userContext: { id } });
        }
      } else {
        props.initRollout();
      }
    };
    if (startOauthFlow) {
      setQueryDetails(urlDetails);

      const redirectUri = query.redirect_uri ? String(query.redirect_uri || "") : ((query.source_campaign || query.source_distribution_data) ? getDefaultRedirectURI() : "");
      const clientId = String(query.client_id || process.env.REACT_APP_ACCOUNTS_CLIENT_ID);
      let _state;
      if (campaignSign) {
        _state = btoa(`campaign:${campaignSign}:${redirectUri}`); // review if redirectURI is needed
      }

      if (distributionSign) {
        _state = btoa(`distribution:${query.source_distribution_data}`);
      }

      window.location.href = getOAuthUrl(
          redirectUri,
          clientId,
          true,
          _state
      );
    }
    fetchCurrentUser().catch(() => {
      props.initRollout();
    });
  }, []);

  if (getAuthPostFlag()) {
    return <Loader />;
  }

  if (startOauthFlow) {
    return <Loader />;
  }

  return (
      <ConfigProvider prefixCls={"syngenta-ant"}>
      <div className="root-inner">
        <Router history={history}>
          <Suspense fallback={<Loader />}>
            <Switch>
              <SentryRoute path="/" component={AsyncMainRoutes} />
            </Switch>
          </Suspense>
        </Router>
      </div>
      </ConfigProvider>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => ({
  initRollout: (configs: any) => dispatch(initRollout(configs)),
  fetchUserProfile: () => dispatch(fetchUserProfile())
});

export default connect(mapStateToProps, mapDispatchToProps)(Sentry.withProfiler(AppComponent));
