import {
  FETCH_USER_WORKSPACE_SUCCESS,
  FETCH_USER_WORKSPACE_ERROR,
  FETCH_USER_WORKSPACE_LOADING,
} from "../actions/types";

const initialState = {
  loading: false,
  error: null,
  success: false,
  workspace: {},
};

export const workspace = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_USER_WORKSPACE_LOADING:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FETCH_USER_WORKSPACE_SUCCESS:
      const { id } = action.payload;
      return {
        ...state,
        loading: false,
        success: true,
        workspace: { ...state.workspace, [id]: action.payload },
      };
    case FETCH_USER_WORKSPACE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
