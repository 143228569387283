export const SET_REDEEM_VOUCHER_SUCCESS: string = "SET_REDEEM_VOUCHER_SUCCESS";
export const SET_REDEEM_VOUCHER_ERROR: string = "SET_REDEEM_VOUCHER_ERROR";
export const SET_REDEEM_VOUCHER_LOADING: string = "SET_REDEEM_VOUCHER_LOADING";

export const SET_REDEEM_ACCESS_KEY_LOADING: string = "SET_REDEEM_ACCESS_KEY_LOADING";
export const SET_REDEEM_ACCESS_KEY_ERROR: string = "SET_REDEEM_ACCESS_KEY_ERROR";
export const SET_REDEEM_ACCESS_KEY_SUCCESS: string = "SET_REDEEM_ACCESS_KEY_SUCCESS";

export const SET_REDEEM_CAMPAIGN_VOUCHER_LOADING: string =
  "SET_REDEEM_CAMPAIGN_VOUCHER_LOADING";
export const SET_REDEEM_CAMPAIGN_VOUCHER_SUCCESS: string =
  "SET_REDEEM_CAMPAIGN_VOUCHER_SUCCESS";
export const SET_REDEEM_CAMPAIGN_VOUCHER_ERROR: string =
  "SET_REDEEM_CAMPAIGN_VOUCHER_ERROR";

export const FETCH_USER_PROFILE_SUCCESS: string = "FETCH_USER_PROFILE_SUCCESS";
export const FETCH_USER_PROFILE_ERROR: string = "FETCH_USER_PROFILE_ERROR";
export const FETCH_USER_PROFILE_LOADING: string =
  "  FETCH_USER_PROFILE_LOADING";

export const FETCH_USER_WORKSPACE_SUCCESS: string =
  "FETCH_USER_WORKSPACE_SUCCESS";
export const FETCH_USER_WORKSPACE_ERROR: string =
  "  FETCH_USER_WORKSPACE_ERROR";
export const FETCH_USER_WORKSPACE_LOADING: string =
  "  FETCH_USER_WORKSPACE_LOADING";

export const EDIT_USER_WORKSPACE_SUCCESS: string =
  "EDIT_USER_WORKSPACE_SUCCESS";
export const EDIT_USER_WORKSPACE_ERROR: string = "EDIT_USER_WORKSPACE_ERROR";
export const EDIT_USER_WORKSPACE_LOADING: string =
  "  EDIT_USER_WORKSPACE_LOADING";

export const FETCH_APPS_SUCCESS: string = "FETCH_APPS_SUCCESS";
export const FETCH_APPS_ERROR: string = " FETCH_APPS_ERROR";
export const FETCH_APPS_LOADING: string = "FETCH_APPS_LOADING";

export const SETUP_ROLLOUT: string = "SETUP_ROLLOUT";
export const SETUP_ROLLOUT_SUCCESS: string = " SETUP_ROLLOUT_SUCCESS";
export const SETUP_ROLLOUT_ERROR: string = "SETUP_ROLLOUT_ERROR";

export const ADD_PASSWORD: string = "ADD_PASSWORD";

export const SAVE_V2_LEGAL_DOCS: string = "SAVE_V2_LEGAL_DOCS";
