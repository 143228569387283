import Rox from "rox-browser";
import {
  SETUP_ROLLOUT,
  SETUP_ROLLOUT_SUCCESS,
  SETUP_ROLLOUT_ERROR,
} from "./types";

// Create a Roxflag in the flags container class
const featureFlags: any = {
  overrideVoucherFlow: new Rox.Flag(true),
  goToWorkSpaceIfSupportAgent: new Rox.RoxNumber(0, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  defaultPlansCount: new Rox.RoxNumber(0, [0, 1, 2, 3, 4]),
  isExternalIdpLoginButtonVisible: new Rox.Flag(false),
  bypassTermsFlow: new Rox.Flag(false),
  showCreateAccountLinkOnLogin: new Rox.Flag(false)
};

export const getFeatureFlags = (context: any) => {
  const targetObj: any = {};
  Object.keys(featureFlags).forEach((flagKey: string) => {
    // eslint-disable-next-line
    if (featureFlags[flagKey]._type === "boolean") {
      targetObj[flagKey] = featureFlags[flagKey].isEnabled(context);
    } else {
      targetObj[flagKey] = featureFlags[flagKey].getValue(context);
    }
    return flagKey;
  });
  return targetObj;
};

export function initRollout(configs: any) {
  const { forceDefault, userContext } = configs || {};
  return (dispatch: any) => {
    dispatch({ type: SETUP_ROLLOUT });
    const ROLLOUT_KEY: any = process.env.REACT_APP_ROLLOUT_KEY;
    Rox.register("", featureFlags);
    if (forceDefault) {
      dispatch(rolloutSuccess(getFeatureFlags(featureFlags)));
    } else {
      // Set global context
      if (userContext) {
        Rox.setContext(userContext);
        Rox.setCustomStringProperty("user.id", userContext.id);
      }
      return Rox.setup(ROLLOUT_KEY, {})
        .then(() => {
          setTimeout(() => {
            // eslint-disable-next-line
            console.log(
              "featureFlags initialized - ",
              getFeatureFlags(featureFlags)
            );
            dispatch(rolloutSuccess(getFeatureFlags(featureFlags)));
          }, 100);
        })
        .catch((error: any) => {
          return dispatch(rolloutError(error));
        });
    }
  };
}

export function rolloutSuccess(payload: any) {
  return {
    type: SETUP_ROLLOUT_SUCCESS,
    payload: payload,
  };
}
export function rolloutError(error: any) {
  return {
    type: SETUP_ROLLOUT_ERROR,
    error,
  };
}
