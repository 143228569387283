import {
  SET_REDEEM_ACCESS_KEY_ERROR,
  SET_REDEEM_ACCESS_KEY_LOADING,
  SET_REDEEM_ACCESS_KEY_SUCCESS
} from "../actions/types";

const initialState = {
  loading: false,
  error: null,
  success: false,
  data: null
};

export const accessKey = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_REDEEM_ACCESS_KEY_LOADING:
      return {
        ...state,
        data: null,
        loading: true,
        success: false,
        error: false
      };
    case SET_REDEEM_ACCESS_KEY_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error
      };
    case SET_REDEEM_ACCESS_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: action.payload
      };
    default:
      return state;
  }
};
