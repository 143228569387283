/**
 * i18n : https://react.i18next.com/latest/using-with-hooks
 */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import moment from 'moment';
// import { getLanguageCode } from './utils/common-methods';
import { getLocaleTempUsingCookies } from "./core/common-methods";

const getLanguageCode = (selectedLang) => {
  return selectedLang.includes('pt') ? 'pt-BR' : selectedLang; // .split("-")[0];
};
const lng = getLanguageCode(
  getLocaleTempUsingCookies() || navigator.language || 'en',
);
moment.locale(lng);

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    preload: ['en'],
    // fallbackLng: 'en',
    lng: lng,
    react: {
      useSuspense: false,
    },
    debug: process.env.node_env !== 'production',
   // keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/i18n/{{lng}}.json',
    },
  });

// eslint-disable-next-line
export default i18n;
