import { combineReducers } from "redux";
import { user } from "./user";
import { voucher } from "./voucher";
import { workspace } from "./workspace";
import { apps } from "./apps";
import { rollout } from "./rollout";
import { signUpPass } from './signUpPass';
import { legalDocs } from "./legalDocuments";
import { accessKey } from "./accessKey";

export const rootReducer = combineReducers({
  user,
  voucher,
  workspace,
  apps,
  rollout,
  signUpPass,
  legalDocs,
  accessKey
});
