import defaultLogo from "assets/images/cropwise_logo.svg";
import syngentaLogo from "assets/images/syngenta_logo.svg";

const brands: { [index: string]: { name: string; logo: any, accounts_url: string; } } = {
  syngenta: {
    name: "Syngenta Digital",
    logo: syngentaLogo,
    accounts_url: (process.env.REACT_APP_ACCOUNTS_MANAGEMENT || "").replace("cropwise.com", "syngentadigital.co.uk")
  },
  default: {
    name: "Cropwise",
    logo: defaultLogo,
    accounts_url: (process.env.REACT_APP_ACCOUNTS_MANAGEMENT || "")
  }
};

export const getBrandLogo = () => getBrand().logo;
export const getBrandName = () => getBrand().name;

export function getBrand () {
  const href = window.location.href;

  if (
    href.match(/uk.+\.cropwise\.com/i) ||
    href.includes("syngentadigital.co.uk")
  ) {
    return brands.syngenta;
  }
  return brands.default;
}

export const getURLDomain = () => {
  const urlDomain = window.location.href;
  if (urlDomain.includes("cropwise.com") || urlDomain.includes("localhost")) {
    return "cropwise";
  } else if (urlDomain.includes("syngentadigital.co.uk")) {
    return "syngenta";
  }
  return undefined;
};
