import { SAVE_V2_LEGAL_DOCS } from "app-redux/actions/types"

const initialState = {
  legalDocs: []
}

export const legalDocs = (state = initialState, action: any) => {
  if (action.type === SAVE_V2_LEGAL_DOCS) {
    return {
      ...state,
      legalDocs: action.payload
    }
  } else {
    return state
  }
}