import {
  SET_REDEEM_VOUCHER_LOADING,
  SET_REDEEM_VOUCHER_ERROR,
  SET_REDEEM_VOUCHER_SUCCESS,
  SET_REDEEM_CAMPAIGN_VOUCHER_LOADING,
  SET_REDEEM_CAMPAIGN_VOUCHER_SUCCESS,
  SET_REDEEM_CAMPAIGN_VOUCHER_ERROR
} from "../actions/types";

const initialState = {
  loading: false,
  error: null,
  success: false,
  campaignLoading: false,
  campaignError: null,
  campaignSuccess: false,
  data: {}
};

export const voucher = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_REDEEM_VOUCHER_LOADING:
      return {
        ...state,
        data: {},
        loading: true,
        success: false,
        error: false
      };
    case SET_REDEEM_VOUCHER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error
      };
    case SET_REDEEM_VOUCHER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: action.payload
      };
    case SET_REDEEM_CAMPAIGN_VOUCHER_LOADING:
      return {
        ...state,
        data: {},
        campaignLoading: true,
        campaignSuccess: false,
        campaignError: false
      };
    case SET_REDEEM_CAMPAIGN_VOUCHER_SUCCESS:
      return {
        ...state,
        campaignLoading: false,
        campaignSuccess: true,
        campaignError: null,
        data: action.payload
      };
    case SET_REDEEM_CAMPAIGN_VOUCHER_ERROR:
      return {
        ...state,
        campaignLoading: false,
        campaignSuccess: false,
        campaignError: action.error
      };
    default:
      return state;
  }
};
