import { store } from "app-redux/store";
import { AxiosResponse } from "axios";
import { Buffer } from "buffer";
import { CookieStorage } from "cookie-storage";
import first from "lodash/first";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import moment from "moment";
import qs from "querystring";
import { isMobile, isTablet } from "react-device-detect";
import { AppConstants } from "../core/app.constants";
import { headers, redirectPost, request } from "../core/axios";
import { getParamValue } from "../core/getUrlParamsByName";
import { isLangCodeValid } from "../core/utils/LanguageList";

const SESSION_STORAGE_ACCESS_TOKEN = "tempTokens";
const SESSION_SIGNUP_INIT = "SessionSignupInit"
const SESSION_STORAGE_LINK_ID = "linkId";
const SESSION_STORAGE_CAMPAIGN_ID = "campaignId";
const SESSION_PATH_TO_AUTHENTICATE = "PathToAuthenticate";
const SESSION_USER_DETAILS = "SESSION_USER_DETAILS";
const SESSION_AUTH_POST_FLAG = "AUTH_POST_FLAG";
const SESSION_SIGNUP_DATA = "signupdata";
const SESSION_DISTRIBUTION_ACCESS_KEY = "distribution_access_key";

const cookieStorage = new CookieStorage();

export const setSignupData = (email: string) => {
  let bValue = btoa(email);
  sessionStorage.setItem(SESSION_SIGNUP_DATA, bValue);
};

export const getSignupData = () => {
  let obj = sessionStorage.getItem(SESSION_SIGNUP_DATA);
  return obj ? atob(obj) : "";
};

export const clearSignupData = () => {
  sessionStorage.removeItem(SESSION_SIGNUP_DATA);
};

export interface IAuthTokenRequestDTO {
  readonly grant_type: "authorization_code";
  readonly client_id: string;
  readonly redirect_uri: string;
  readonly code: string;
}

export interface IAuthTokenBasicRequestDTO {
  readonly grant_type: "password";
  readonly username: string;
  readonly password: string;
}

export interface IAuthTokenResponseDTO {
  readonly access_token: string;
  readonly refresh_token: string;
}

export interface IAuthTokenRequest {
  readonly username: string;
  readonly password: string;
}

export const isAccessTokenAvailable = () => !!localStorage.getItem("tokens");
export const setAccessToken = (token: any) =>
  localStorage.setItem("tokens", token);
export const getAccessToken = (key: any) =>
  sessionStorage.getItem(key || "tokens") || "";
export const clearAccessToken = () => localStorage.removeItem("tokens");

export const setLocaleUsingCookies = (lang: string) => {
  const env = process.env.REACT_APP_ENV_NAME;
  if (env) {
    const domain = getDomain();
    const key = `i18nextLng_${env}`;
    cookieStorage.setItem(key, lang, {
      path: "/",
      domain,
    });
  }
};

export const getLocaleUsingCookies = () => {
  const env = process.env.REACT_APP_ENV_NAME;
  const key = `i18nextLng_${env}`;
  return cookieStorage.getItem(key);
};

export const setLocaleTempUsingCookies = (lang: string) => {
  const env = process.env.REACT_APP_ENV_NAME;
  if (env) {
    const key = `i18nextLngTemp_${env}`;
    cookieStorage.setItem(key, lang);
  }
};

export const getDefaultRedirectURI = () => {
  const env = process.env.REACT_APP_ENV_NAME;
  if (env) {
    return "https://" + (env === "production" ? "" : (env + ".")) + "app." + getDomain();
  }
}

export const redirectUserToAvailableProductsDeepAction = (confirmationMethod: string, methodData: string) => {
  window.location.href = `${getDeepActionUrl()}/app/deep-actions/available-products?confirmation_method=${confirmationMethod}&callback_uri=${getAccountsAppUrl()}&state=${methodData}`;
};

export const getLocaleTempUsingCookies = () => {
  const env = process.env.REACT_APP_ENV_NAME;
  const key = `i18nextLngTemp_${env}`;
  return cookieStorage.getItem(key);
};

export const getProgressByValues = (
  isPasswordValid: boolean,
  isMobileNumberValid: boolean,
  termsCheckbox: boolean
) => {
  let count = 1;
  if (isPasswordValid) {
    count++;
  }
  if (isMobileNumberValid) {
    count++;
  }
  if (termsCheckbox) {
    count++;
  }
  return count;
};

export const isEmptyStr = (str?: string) => {
  if (str && str.length > 0) {
    return true;
  }
  return false;
};

const cleanupErr = (key: any) => {
  try {
    return JSON.parse(getAccessToken(key)).access_token;
  } catch (e) {
    return "";
  }
}

export const getHeaders = (key: any) => ({
  common: {
    Authorization: `Bearer ${cleanupErr(key)}`,
  },
});

export const getHeadersNodeFetch = (key: any) => {
  return {
    Authorization: `Bearer ${cleanupErr(key)}`,
    'Content-Type': 'application/json'
  }
};

export const getLangHeaders = (key: any) => {
  let lang = getLocaleUsingCookies() || "en";
  if (lang === "pt") {
    lang = "pt-BR";
  }
  return {
    common: {
      Authorization: `Bearer ${JSON.parse(getAccessToken(key)).access_token}`,
      "Accept-Language": lang,
    },
  };
};

export const getInitialName = (name: string) => {
  if (isEmpty(name)) {
    return "";
  }
  const splitedNames = name.split(" ");
  const firstName = first(splitedNames) || "";
  const lastName = last(splitedNames) || "";
  if (splitedNames.length >= 2) {
    return `${firstName.charAt(0).toUpperCase()} ${lastName
      .charAt(0)
      .toUpperCase()}`;
  }
  return firstName.charAt(0).toUpperCase();
};

export const setTimeoutPromise = async (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const isSearchSubStringFound = (
  searchString = "",
  fields: string[] = []
) =>
  !isEmpty(
    fields.filter(
      (field) =>
        (field || "").toLowerCase().indexOf(searchString.toLowerCase()) >= 0
    )
  );

export const CommaSeparatedList = (a: [], b: []) => {
  try {
    if (!b) {
      return a.join(", ");
    }
    return get(a, b).join(", ");
  } catch (e) {
    // do nothing
    return "";
  }
};

export const getOAuthUrl = (
  redirect_uri?: string,
  client_id?: string,
  addEndPath?: boolean,
  state?: string
) => {
  const { O_AUTH } = AppConstants;
  let host = window.location.origin;
  const final_redirect_uri = mountRedirectUrl(redirect_uri as string, addEndPath as boolean)
  if (host.includes("localhost")) {
    if (process.env.REACT_APP_ACCOUNTS_MANAGEMENT) {
      host = process.env.REACT_APP_ACCOUNTS_MANAGEMENT;
    }
  }
  // patch to force https
  if (host.includes("http://")) {
    const env = process.env.REACT_APP_ENV_NAME;
    if (env !== "local") {
      host = host.replace("http://", "https://");
    }
  }
  return `${host}/oauth/authorize?response_type=${O_AUTH.responseCode
    }&client_id=${getAppClientId(final_redirect_uri, store.getState().apps.data, client_id)
    }&redirect_uri=${final_redirect_uri
    }${state ? `&state=${state}` : ""}`;
};

const mountRedirectUrl = (redirectUri: string, addEndPath: boolean) => {
  const result = `${redirectUri}${addEndPath === false ||
    !redirectUri?.startsWith("http") ||
    redirectUri?.includes("/authenticate")
    ? ""
    : redirectUri[redirectUri.length - 1] !== '/'
      ? "/authenticate"
      : "authenticate"
    }`
  const doubleSlashes = /(\/\/authenticate)/
  return result.replace(doubleSlashes, "/authenticate").trim()
}

const getAppClientId = (
  redirectUrl: string | undefined,
  apps: any[],
  clientId: string | undefined
) => {
  if (clientId !== "" && clientId !== process.env.REACT_APP_ACCOUNTS_CLIENT_ID && clientId) {
    return clientId;
  }

  if (redirectUrl === undefined) {
    return process.env.REACT_APP_ACCOUNTS_CLIENT_ID;
  }

  for (let app of apps) {
    for (let client of app.clients) {
      // All redirect URLs are on accounts, so we only use the accounts client id as a last resort
      if (client.client_id === process.env.REACT_APP_ACCOUNTS_CLIENT_ID) {
        continue;
      }

      let found = client.redirect_uris.find((element: any) => element === redirectUrl);

      if (found !== undefined) {
        return client.client_id;
      }
    }
  }

  return process.env.REACT_APP_ACCOUNTS_CLIENT_ID;
}

export const getAccountsAppUrl = () => {
  let host = window.location.origin;
  const env = process.env.REACT_APP_ENV_NAME;
  if (env !== "local") {
    if (host?.includes("localhost")) host = "https://qa.accounts.cropwise.com";
  }
  return host;
};

export const setUserJourneyType = (journey_type: string) => {
  sessionStorage.setItem("userJourneyType", journey_type);
};

export const getUserJourneyType = () => {
  return sessionStorage.getItem("userJourneyType");
};

export const setToSession = (key: string, value: any) => {
  sessionStorage.setItem(key, value);
}

export const getFromSession = (key: string) => {
  return sessionStorage.getItem(key) || '';
}

export const clearSessionByKey = (key: string) => {
  sessionStorage.removeItem(key);
}

export const clearUserJourneyType = () => {
  sessionStorage.removeItem("userJourneyType");
};

export const getLocale = () => {
  let lang = getLocaleUsingCookies() || "en";
  if (lang === "pt" || lang === "pt-BR") {
    return "pt-BR";
  } else if (isLangCodeValid(lang.split("-")[0])) {
    return lang.split("-")[0];
  } else {
    return "en";
  }
};

export const setQueryDetails = (queryDetails: any) => {
  sessionStorage.setItem("queryDetails", queryDetails);
};
export const getQueryDetails = () => {
  return sessionStorage.getItem("queryDetails");
};
export const resetQueryDetails = () => {
  sessionStorage.removeItem("queryDetails");
};

export const setIsSkipClicked = () => {
  sessionStorage.setItem("SKIP", "true");
};
export const getIsSkipClicked = () => {
  return sessionStorage.getItem("SKIP");
};
export const clearIsSkipClicked = () => {
  sessionStorage.removeItem("SKIP");
};

export const setSessionStorageAccessToken = (token: any) => {
  sessionStorage.setItem(SESSION_STORAGE_ACCESS_TOKEN, token);
};
export const getSessionStorageAccessToken = () => {
  return sessionStorage.getItem(SESSION_STORAGE_ACCESS_TOKEN);
};
export const clearSessionStorageAccessToken = () => {
  sessionStorage.removeItem(SESSION_STORAGE_ACCESS_TOKEN);
};

export const setAuthPostFlag = (url: any = "T") => {
  sessionStorage.setItem(SESSION_AUTH_POST_FLAG, url);
};
export const getAuthPostFlag = () => {
  return sessionStorage.getItem(SESSION_AUTH_POST_FLAG);
};
export const clearAuthPostFlag = () => {
  sessionStorage.removeItem(SESSION_AUTH_POST_FLAG);
};

export const authorizerSetDomain = (url = "", authPostFlag: boolean = true) => {
  if (authPostFlag) setAuthPostFlag();
  setTimeout(() => {
    window.location.href = getOAuthUrl(
      String(url),
      getFromSession('client_id') || process.env.REACT_APP_ACCOUNTS_MANAGEMENT_CLIENT_ID,
      false,
      btoa("/app/workspaces")
    );
  }, 100);
};

export const isSessionStorageAccessTokenAvailable = () => {
  return !!sessionStorage.getItem(SESSION_STORAGE_ACCESS_TOKEN);
};

export const setCampaignId = (campaignId: string) => {
  sessionStorage.setItem(SESSION_STORAGE_CAMPAIGN_ID, campaignId);
};
export const getCampaignId = () => {
  return sessionStorage.getItem(SESSION_STORAGE_CAMPAIGN_ID);
};
export const clearCampaignId = () => {
  sessionStorage.removeItem(SESSION_STORAGE_CAMPAIGN_ID);
};

export const setLinkId = (linkId: string) => {
  sessionStorage.setItem(SESSION_STORAGE_LINK_ID, linkId);
};
export const getLinkId = () => {
  return sessionStorage.getItem(SESSION_STORAGE_LINK_ID);
};

export const setDistributionAccessKey = (accessKey: string) => {
  sessionStorage.setItem(SESSION_DISTRIBUTION_ACCESS_KEY, accessKey);
};
export const getDistributionAccessKey = () => {
  return sessionStorage.getItem(SESSION_DISTRIBUTION_ACCESS_KEY);
}
export const clearDistributionAccessKey = () => {
  sessionStorage.removeItem(SESSION_DISTRIBUTION_ACCESS_KEY);
}

export const clearLinkId = () => {
  sessionStorage.removeItem(SESSION_STORAGE_LINK_ID);
};

export const setPathToAuthenticate = (path: string) => {
  sessionStorage.setItem(SESSION_PATH_TO_AUTHENTICATE, path);
};
export const getPathToAuthenticate = () => {
  const pathToAuthenticateFromSession = sessionStorage.getItem(SESSION_PATH_TO_AUTHENTICATE);
  if (pathToAuthenticateFromSession === '/terms-and-conditions') {
    return '/sign-legal-documents';
  }
  return pathToAuthenticateFromSession;
};
export const clearPathToAuthenticate = () => {
  sessionStorage.removeItem(SESSION_PATH_TO_AUTHENTICATE);
};

export const setSessionUserDetails = (path: string) => {
  sessionStorage.setItem(SESSION_USER_DETAILS, path);
};
export const getSessionUserDetails = () => {
  const [username, password] = atob(
    sessionStorage.getItem(SESSION_USER_DETAILS) || ""
  ).split("::");

  const userDetails: IAuthTokenRequest = {
    username: username,
    password: password,
  };
  return userDetails;
};
export const clearSessionUserDetails = () => {
  sessionStorage.removeItem(SESSION_USER_DETAILS);
};

export const mobileDeepLinking = (route: string) => {
  if (
    (isMobile || isTablet) &&
    ["qa", "staging"].includes(process.env.REACT_APP_ENV_NAME!)
  ) {
    const forwardParams = window.location.search;

    //Deep link URL for existing users with app already installed on their device
    //@ts-ignore
    window.location = `CropwiseBase://${route}${forwardParams} com.cropwise`;
  }
};

export const handleOAuthRedirection = () => {
  const authenticationUrl = `${window.location.origin}/authenticate`;
  window.location.href = getOAuthUrl(
    authenticationUrl,
    "",
    false,
    btoa(getParamValue("referrer_url") as string)
  );
};

export const getSearchParam = (locationSearch: any, param: any) => {
  return new URLSearchParams(locationSearch).get(param) || '';
};

export const decodeURIBase64 = (uri: string) => {
  return Buffer.from(uri, 'base64').toString();
};

export const handleLogin = async (signup: boolean = false) => {
  let redirect_path = signup ? `${window.location.origin}/auth` : `${window.location.origin}/authenticate`;

  const userDetails: IAuthTokenRequestDTO = {
    grant_type: "authorization_code",
    client_id: process.env.REACT_APP_ACCOUNTS_CLIENT_ID as string,
    redirect_uri: redirect_path,
    code: getParamValue("code") as string,
  };
  const config = {
    headers,
  };
  try {
    const response = await request.post<
      any,
      AxiosResponse<IAuthTokenResponseDTO>
    >(`/oauth/token`, qs.stringify(userDetails as any), config);
    const tokens = response.data;
    setSessionStorageAccessToken(JSON.stringify(tokens));
    return tokens;
  } catch (error) {
    throw error;
  }
};

export const handleLoginBasic = async (
  authTokenRequestDTO: IAuthTokenRequestDTO
) => {
  const userDetails: IAuthTokenRequestDTO = { ...authTokenRequestDTO };
  const config = {
    headers,
  };
  try {
    const response = await request.post<
      any,
      AxiosResponse<IAuthTokenResponseDTO>
    >(
      `/oauth/token`,
      qs.stringify({ ...(userDetails as any), grant_type: "password" }),
      config
    );
    const tokens = response.data;
    setSessionStorageAccessToken(JSON.stringify(tokens));
    return tokens;
  } catch (error) {
    throw error;
  }
};

export const initialize = ({ token }: { token?: any }) => {
  if (token) {
    const tokenObj = {
      access_token: token,
    };
    setSessionStorageAccessToken(JSON.stringify(tokenObj));
  }
};

export const checkAccess = (
  user: any,
  termsData: any,
) => {
  const signedUpAt = get(user, "created_at");
  let actionTobeTaken = "continue";
  const userJourneyType = getUserJourneyType();

  if (user) {
    if (signedUpAt && moment().diff(moment(signedUpAt), "minutes") < 30) {
      if (
        userJourneyType &&
        ["self_service"].includes(userJourneyType) &&
        signedUpAt &&
        moment().diff(moment(signedUpAt), "minutes") < 5 &&
        (!isEmpty(getCampaignId()) || !isEmpty(getDistributionAccessKey()))
      ) {
        actionTobeTaken = "voucher-redeem";
      }
    }

    if (actionTobeTaken !== "voucher-redeem" && !isEmpty(termsData)) {
      actionTobeTaken = "legal-documents"
    }

    return actionTobeTaken;
  }
};

export const getDomain = () => {
  const domain = window.location.hostname.includes("cropwise.com")
    ? "cropwise.com"
    : "syngentadigital.co.uk";
  return domain;
};

export const setNewUserSession = () => {
  const env = process.env.REACT_APP_ENV_NAME;
  const domain = getDomain();
  cookieStorage.setItem(`resetUserSession_${env}`, "true", {
    path: "/",
    domain,
  });
};

export const deleteCookie = (name: String) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const getDeepActionUrl = () => {
  if (isUK()) {
    return process.env.REACT_APP_DEEP_ACTIONS_UK_BASE_URL;
  } else {
    return process.env.REACT_APP_DEEP_ACTIONS_CW_BASE_URL;
  }
};

export const getDeepActionCBUrl = () => {
  return `${window.location.origin}${window.location.pathname}`
}

export const isCropwise = () => {
  const href = window.location.href;
  if (href.includes("syngentadigital.co.uk")) {
    return false;
  }
  return true
}

export const isUK = () => {
  const { host } = getAppHostsInfo();
  if (host === 'UK') {
    return true;
  }
  return false;
};

const qaLinkConstant = 'qa.accounts.cropwise.com';

export const getAppHostsInfo: any = () => {
  const domainMapping: any = {
    'localhost:3000': { host: 'GLOBAL', account: qaLinkConstant },
    'localhost:4000': { host: 'UK', account: 'uk-qa.accounts.cropwise.com' },
    'localhost:5000': { host: 'UK', account: 'qa.accounts.syngentadigital.co.uk' },
    'beta.app.cropwise.com': { host: 'GLOBAL', account: 'beta.accounts.cropwise.com' },
    'beta.app.syngentadigital.co.uk': {
      host: 'UK',
      account: 'beta.accounts.syngentadigital.co.uk'
    },
    'dev.app.cropwise.com': { host: 'GLOBAL', account: 'dev.accounts.cropwise.com' },
    'uk-dev.app.cropwise.com': { host: 'UK', account: 'uk-dev.accounts.cropwise.com' },
    'dev.app.syngentadigital.co.uk': { host: 'UK', account: 'dev.accounts.syngentadigital.co.uk' },
    'qa.app.cropwise.com': { host: 'GLOBAL', account: qaLinkConstant },
    'staff.qa.cropwise.com': { host: 'GLOBAL', account: qaLinkConstant },
    'uk-qa.app.cropwise.com': { host: 'UK', account: 'uk-qa.accounts.cropwise.com' },
    'qa.app.syngentadigital.co.uk': { host: 'UK', account: 'qa.accounts.syngentadigital.co.uk' },
    'staging.app.cropwise.com': { host: 'GLOBAL', account: 'staging.accounts.cropwise.com' },
    'uk-staging.app.cropwise.com': { host: 'UK', account: 'uk-staging.accounts.cropwise.com' },
    'staging.app.syngentadigital.co.uk': {
      host: 'UK',
      account: 'staging.accounts.syngentadigital.co.uk'
    },
    'app.cropwise.com': { host: 'GLOBAL', account: 'accounts.cropwise.com' },
    'uk.app.cropwise.com': { host: 'UK', account: 'uk.accounts.cropwise.com' },
    'app.syngentadigital.co.uk': { host: 'UK', account: 'accounts.syngentadigital.co.uk' }
  };

  const { host } = window.location;
  return {
    host: (!isEmpty(domainMapping[host]) && domainMapping[host].host) || 'GLOBAL',
    account: !isEmpty(domainMapping[host]) && domainMapping[host].account,
    hostsList: [...new Set(Object.values(domainMapping).map((d: any) => d.host))]
  };
};

export const matchBaseProduct = (product: any) => {
  return (
    product.id === AppConstants.APP_ID.BASE ||
    product.name.toLowerCase() === "base" ||
    product.name.toLowerCase() === "cropwise base"
  );
};

export const matchInvitedProduct = (productUri: string | null) => {
  const redirectUri = getFromSession('redirect_uri');
  if (redirectUri && productUri) {
    const redirectUrl = new URL(redirectUri)
    return productUri?.includes(redirectUrl.host);
  } else {
    return false;
  }
}

export const getRedirectUrlFromParams = () => {
  try {
    const parsedQuery = qs.parse(window.location.search);
    return parsedQuery['redirect_uri'];
  } catch (_e) {
    return '';
  }
}

export const getUrlParam = (paramKey: any) => {
  try {
    const parsedQuery = qs.parse(window.location.search.replace("?", ""));
    return typeof paramKey == 'string' ? parsedQuery[paramKey] : parsedQuery;
  } catch (_e) {
    return '';
  }
}

export const getEmailFromUrlParams = () => {
  try {
    const emailRegex = /userEmail=[A-z0-9+_.'-]+@[a-z0-9+-]+\.[a-z]+(\.[a-z]+)?/;
    const result = window.location.search.match(emailRegex);
    if (result) return result[0].replace("userEmail=", "");
    return "";
  } catch (e) {
    return "";
  }
};

export const setSessionSignupInit = (queryString: any) => {
  sessionStorage.setItem(SESSION_SIGNUP_INIT, queryString);
};
export const getSessionSignupInit = () => {
  return sessionStorage.getItem(SESSION_SIGNUP_INIT);
};
export const clearSessionSignupInit = () => {
  sessionStorage.removeItem(SESSION_SIGNUP_INIT);
};

export const redirectUserToApplication = (siteUri: string) => {
  if (isMobile || isTablet) {
    const authTokenRequestDTO = getSessionUserDetails();
    redirectPost("/login", authTokenRequestDTO);
  } else {
    authorizerSetDomain(siteUri);
  }
}
