let langs = [
  {
    key: "en",
    label: "English (USA)",
    shortLabel: "EN",
    variations: ["en-US"],
  },
  { key: "pt-BR", label: "Português", shortLabel: "PT", variations: ["pt"] },
  { key: "fr", label: "French", shortLabel: "FR", variations: ["fr-FR"] },
  { key: "de", label: "German", shortLabel: "DE", variations: [] },
  { key: "pl", label: "Polski", shortLabel: "PL", variations: [] },
  { key: "es", label: "Spanish", shortLabel: "ES", variations: [] },
  {
    key: "es-419",
    label: "Spanish (Latin America)",
    shortLabel: "ES-419",
    variations: [],
  },
  { key: "bg", label: "Bulgarian", shortLabel: "BG", variations: [] },
  { key: "hu", label: "Hungarian", shortLabel: "HU", variations: [] },
  { key: "ro", label: "Romanian", shortLabel: "RO", variations: [] },
  { key: "el", label: "Greek", shortLabel: "EL", variations: [] },
  { key: "nl", label: "Dutch", shortLabel: "NL", variations: [] },
  { key: "da", label: "Danish", shortLabel: "DA", variations: [] },
  { key: "sv", label: "Swedish", shortLabel: "SV", variations: [] },
  { key: "uk", label: "Ukrainian", shortLabel: "UK", variations: [] },
  { key: "it", label: "Italian", shortLabel: "IT", variations: [] },
  { key: "sk", label: "Slovakian", shortLabel: "SK", variations: [] },
  { key: "ru", label: "Russian", shortLabel: "RU", variations: [] },
  { key: "cs", label: "Czech", shortLabel: "CS", variations: [] },
];

if (["", "local", "qa", "staging"].includes(process.env.REACT_APP_ENV_NAME!)) {
  langs.push({
    key: "ja",
    label: "Japanese",
    shortLabel: "JA",
    variations: [],
  });
}

export const SUPPORTED_LANGS = langs;

export function getKeyFromVariation(variation: string) {
  return langs.find(
    (lang) => lang.key === variation || lang.variations?.includes(variation)
  )?.key;
}

export function isLangCodeValid(key: string) {
  const A = langs.filter((v) => {
    return v.key === key;
  });
  return A.length === 1;
}
