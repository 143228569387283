export const getParamValue = (parameterName: string) => {
  let result = null;
  let tmp = [];
  const items = window.location.search.substr(1).split("&");
  for (const item of items) {
    tmp = item.split("=");
    if (tmp[0] === parameterName) {
      result = decodeURIComponent(tmp[1]);
    }
  }
  return result;
};
