import axios from "axios";
import { isWebUri } from "valid-url";
// import isEmpty from "lodash/isEmpty";
// import { clearSessionUserDetails, getIsSkipClicked } from "./common-methods";
import { getBrand } from "./utils/BrandingUtils";

export const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  authorization: "Basic " + btoa(`${process.env.REACT_APP_ACCOUNTS_CLIENT_ID}:`)
};

const urlToOverride = localStorage.getItem("baseURL") || "";
let baseURL = process.env.REACT_APP_API_URL;

if (isWebUri(urlToOverride)) {
  baseURL = urlToOverride;
}

export const request = axios.create({
  baseURL
  // timeout: 1000,
  // headers
});

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data && error.response.data.location) {
      window.location = error.response.data.location;
    } else {
      return Promise.reject(error);
    }
  }
);
export const redirectPost = (url: string, data: any) => {
  const form = document.createElement("form");
  const redirectBaseUrl = getBrand().accounts_url || "";
  document.body.appendChild(form);
  form.method = "post";
  if (url?.includes("https://")) {
    form.action =
      (url[url.length - 1] === "/" && url.substring(0, url.length - 1)) || url;
  } else {
    form.action = (redirectBaseUrl || "") + url;
  }
  Object.keys(data).forEach((name) => {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = name;
    input.value = data[name];
    form.appendChild(input);
  });
  // Added below step to persist the session details in skip redirection(Consumer app to Accounts TnC Page)
  // Cleared the SKIP flag on signin, signup and TnC continiue action
  // const isSkipClicked = getIsSkipClicked();
  // if (isEmpty(isSkipClicked) || isSkipClicked === "null") {
  //   clearSessionUserDetails();
  // }
  form.submit();
};
